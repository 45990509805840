// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request, { ClientError } from "graphql-request";

import {
  DataForTagsByIndexesDocument,
  DataReceivedStartingDocument,
  DataReceivedStartingSubscription,
} from "../gql/graphql";
import { DerivedTag, Tag } from "../model/tags";
import { useMemo } from "react";
import { useSubscription } from "urql";

type Reducer<T> = (data: Datum[]) => T;

const endpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
const token = process.env.REACT_APP_AUTH_TOKEN;

export interface UseOpcDataOptions<T> {
  tags: number[];
  name?: string;
  start?: Date;
  end?: Date;
  reducer?: Reducer<T>;
}

export async function consumePaginatedData<T>(
  { tags, start, end }: UseOpcDataOptions<T>,
  after?: string
): Promise<Datum[]> {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 10000);
  try {
    const { dataForTagsByIndexes } = await request({
      url: endpoint as string,
      requestHeaders: {
        Authorization: `Bearer ${token}`,
      },
      document: DataForTagsByIndexesDocument,
      signal: controller.signal,
      variables: {
        first: 1000,
        tags: tags,
        after,
        start: start?.toISOString(),
        end: end?.toISOString(),
      },
    });
    console.log("got", dataForTagsByIndexes);
    clearTimeout(timeoutId);

    if (!dataForTagsByIndexes) {
      return [] as Datum[];
    }

    const dataPoints = dataForTagsByIndexes.edges
      .map((edge) => convertQueryNode(edge.node, (input: number) => input))
      .filter((node) => node !== null);

    if (!dataForTagsByIndexes.pageInfo.hasNextPage) {
      return dataPoints;
    }

    const nextDataPoints = await consumePaginatedData(
      { tags, start, end },
      dataForTagsByIndexes.pageInfo.endCursor
    );

    return [...dataPoints, ...nextDataPoints];
  } catch (e) {
    if (e instanceof ClientError) {
      console.error("Error fetching data:", e.response.errors);
      throw e;
    }
    if ((e as Error).name === "AbortError") {
      console.error("Request timed out");
      throw e;
    }
    console.error("Error fetching data:", e);
    throw e;
  }
}

export type QueryNode =
  | {
      __typename?: "BoolDatum";
      id: number;
      time: string;
      boolVal: boolean;
      tag: { __typename?: "Tag"; name: string; id: number };
    }
  | {
      __typename?: "FloatDatum";
      id: number;
      time: string;
      floatVal: number;
      tag: { __typename?: "Tag"; name: string; id: number };
    }
  | {
      __typename?: "IntDatum";
      id: number;
      time: string;
      intVal: number;
      tag: { __typename?: "Tag"; name: string; id: number };
    }
  | {
      __typename?: "StringDatum";
      id: number;
      time: string;
      stringVal: string;
      tag: { __typename?: "Tag"; name: string; id: number };
    };

export interface Datum {
  id: number;
  time: Date;
  timeUnix: number;
  value: number | string | boolean;
  tag: {
    name: string;
    id: number;
  };
}

function convertQueryNode(node: QueryNode, transform: (input: number) => number): Datum | null {
  const time = new Date(node.time);
  const timeUnix = time.getTime();
  switch (node.__typename) {
    case "BoolDatum":
      return { id: node.id, value: node.boolVal, time: time, tag: node.tag, timeUnix };
    case "FloatDatum":
      return { id: node.id, value: transform(node.floatVal), time: time, tag: node.tag, timeUnix };
    case "IntDatum":
      return { id: node.id, value: transform(node.intVal), time: time, tag: node.tag, timeUnix };
    case "StringDatum":
      return { id: node.id, value: node.stringVal, time: time, tag: node.tag, timeUnix };
    default:
      return null;
  }
}

interface UseLiveOpcDataOpts {
  tags: (Tag | DerivedTag)[];
  windowMin: number;
}

function startTime(timeFrameMin: number): Date {
  return new Date(new Date().getTime() - timeFrameMin * 60 * 1000);
}

export function useLiveOpcData({ tags, windowMin }: UseLiveOpcDataOpts) {
  const subVars = useMemo(() => {
    console.log("updating subVars", tags, windowMin);
    return {
      tags: tags.map((tag) => ("id" in tag ? tag.id : null)).filter((val) => val !== null),
      start: startTime(windowMin).toISOString(),
    };
  }, [tags, windowMin]);
  const handle = useMemo(() => dataHandler(tags, windowMin), [tags, windowMin]);
  const [result] = useSubscription(
    {
      query: DataReceivedStartingDocument,
      variables: subVars,
    },
    handle
  );
  return result;
}

function dataHandler(tags: (Tag | DerivedTag)[], windowMin: number) {
  const tagLookup = tags.reduce((acc, tag) => {
    if (!("id" in tag)) {
      return acc;
    }
    acc[tag.id] = tag;
    return acc;
  }, {} as Record<number, Tag | DerivedTag>);

  function handleNewData(
    acc: Record<string, Datum[]> = {},
    data: DataReceivedStartingSubscription
  ): Record<string, Datum[]> {
    const newData = data.dataReceivedStarting;
    if (!newData) {
      return acc;
    }
    const tag = tagLookup[newData.tag.id];
    if (!tag) {
      return acc;
    }
    if ("inputs" in tag) {
      return acc;
    }
    const newDataPoint = convertQueryNode(newData, tag.call);
    if (!newDataPoint) {
      return acc;
    }
    if (!acc[tag.reference]) {
      return {
        ...acc,
        [tag.reference]: [newDataPoint],
      };
    }

    const newArray = [...acc[tag.reference], newDataPoint];

    return {
      ...acc,
      [tag.reference]: resampleData(
        newArray
          .filter((datum) => timeInWindow(datum.time, windowMin))
          .sort((a, b) => a.time.getTime() - b.time.getTime()),
        500
      ),
    };
  }

  return handleNewData;
}

function timeInWindow(time: Date, window: number): boolean {
  return new Date().getTime() - time.getTime() < window * 60 * 1000;
}

function timeRange(data: Datum[]): number {
  const minTime = data[0].timeUnix;
  const maxTime = data[data.length - 1].timeUnix;
  return maxTime - minTime;
}

export function resampleData(data: Datum[], nPoints: number): Datum[] {
  if (data.length === 0 || nPoints >= data.length) return data;
  const binSize = timeRange(data) / nPoints;
  const resampledData: Datum[] = [];
  let binStart = data[0].timeUnix;
  let binEnd = binStart + binSize;
  let binData: Datum[] = [];
  for (const datum of data) {
    if (datum.timeUnix > binEnd) {
      resampledData.push(averageData(binData));
      binData = [];
      binStart = binEnd;
      binEnd = binStart + binSize;
    }
    binData.push(datum);
  }
  if (binData.length > 0) {
    resampledData.push(averageData(binData));
  }
  return resampledData;
}

function averageData(data: Datum[]): Datum {
  const sum = data.reduce((acc, datum) => acc + (datum.value as number), 0);
  const average = sum / data.length;
  return { ...data[0], value: average };
}
