import { LabSheetPage } from "./LabSheetPage";
import { Datum, UseOpcDataOptions } from "../../handlers/opcData";
import { AnalyteHourGrouping, FormatAs } from "../../hooks/useFilteredSampleStream";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const sampleLocations = [
  "Cossette",
  "Pr Pulp Water",
  "Diff Supply Water",
  "Raw Jce Exit",
  "PreLimer C#1",
  "PreLimer C#2",
  "PreLimer C#3",
  "PreLimer C#4",
  "PreLimer C#5",
  "PreLimer C#6",
  "Milk of Lime",
  "MainLimer",
  "1st Carb",
  "2nd Carb",
  "Down Thin Juice",
  "Evap Thick Juice",
  "Clarifier",
  "Lime Flume",
  "Main Sewer",
  "Seal Tank",
];

const analytes = [
  "% Sugar",
  "Purity",
  "pH",
  "SO2",
  "RDS",
  "CaO",
  "Brix",
  "Alkalinity",
  "Purity",
  "Lime Salts",
  "Color",
];

const rows: [string, string][] = [
  ["Cossette", "Slice Rate"],
  ["Cossette", "% Sugar"],
  ["Cossette", "Purity"],
  ["Pr Pulp Water", "% Sugar"],
  ["Pr Pulp Water", "pH"],
  ["Diff Supply Water", "pH"],
  ["Diff Supply Water", "SO2"],
  ["Raw Jce Exit", "pH"],
  ["Raw Jce Exit", "RDS"],
  ["Raw Jce Exit", "Purity"],
  ["PreLimer C#1", "pH"],
  ["PreLimer C#2", "pH"],
  ["PreLimer C#3", "pH"],
  ["PreLimer C#4", "pH"],
  ["PreLimer C#5", "pH"],
  ["PreLimer C#6", "pH"],
  ["PreLimer C#6", "CaO"],
  ["In Skip", "Skips Loaded"],
  ["Milk of Lime", "Brix"],
  ["Milk of Lime", "CaO"],
  ["MainLimer", "pH"],
  ["MainLimer", "CaO"],
  ["1st Carb", "pH"],
  ["1st Carb", "Alkalinity"],
  ["2nd Carb", "pH"],
  ["2nd Carb", "Alkalinity"],
  ["Down Thin Juice", "pH"],
  ["Down Thin Juice", "Purity"],
  ["Down Thin Juice", "Lime Salts"],
  ["Down Thin Juice", "RDS"],
  ["Down Thin Juice", "Color"],
  ["Evap Thick Juice", "pH"],
  ["Evap Thick Juice", "Purity"],
  ["Evap Thick Juice", "RDS"],
  ["Evap Thick Juice", "Color"],
  ["Clarifier", "% Sugar"],
  ["Clarifier", "pH"],
  ["Lime Flume", "% Sugar"],
  ["Main Sewer", "% Sugar"],
  ["Seal Tank", "% Sugar"],
  ["Seal Tank", "pH"],
];

const opcData: UseOpcDataOptions<Record<string, AnalyteHourGrouping>>[] = [
  {
    reducer(data) {
      const hourMinMax = data.reduce(addDatum, {});
      return {
        "Cossette.Slice Rate": toAnalyteHourGrouping(
          "Cossette",
          "Slice Rate",
          hourMinMax,
          FormatAs.Float,
          {
            min: 125,
            max: 135,
          }
        ),
      };
    },
    tags: [40452],
    name: "Tons Sliced",
  },
  {
    reducer: skipsLoadedReducer,
    tags: [37708],
    name: "Skips Loaded",
  },
];

function skipsLoadedReducer(data: Datum[]): Record<string, AnalyteHourGrouping> {
  const hourMinMax = hourDelta(data.reduce(addDatum, {}));
  return {
    "In Skip.Skips Loaded": toAnalyteHourGrouping(
      "In Skip",
      "Skips Loaded",
      hourMinMax,
      FormatAs.Integer
    ),
  };
}

function hourDelta(hg: HourGroup): HourGroup {
  return Object.entries(hg).reduce((acc, [hour, values]) => {
    const min = Math.min(...values) - 1;
    const max = Math.max(...values);
    return { ...acc, [hour]: [max - min] };
  }, {});
}

interface HourGroup {
  [hour: string]: number[];
}

function toAnalyteHourGrouping(
  loc: string,
  analyte: string,
  hourMinMax: HourGroup,
  formatAs?: FormatAs,
  operatingWindow?: { min: number; max: number }
): AnalyteHourGrouping {
  return {
    sampleLocation: loc,
    analyte: analyte,
    values: toValues(hourMinMax),
    operatingWindow: operatingWindow,
    formatAs: formatAs,
  };
}

function toValues(hourMinMax: HourGroup): Record<string, number[]> {
  return Object.entries(hourMinMax).reduce((acc, [hour, values]) => {
    return { ...acc, [hour]: values };
  }, {});
}

function addDatum(acc: HourGroup, { time, value }: Datum): HourGroup {
  const dateHour = dayjs.utc(time.toISOString()).local().format("YYYY-MM-DDTHH");
  const existing = acc[dateHour];
  if (!existing) {
    return {
      ...acc,
      [dateHour]: [value as number],
    };
  }
  return {
    ...acc,
    [dateHour]: [...existing, value as number],
  };
}

export const BeetEndPage = () => {
  return (
    <>
      <LabSheetPage
        sampleLocations={sampleLocations}
        analytes={analytes}
        rows={rows}
        opcData={opcData}
      />
    </>
  );
};
