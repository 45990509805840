import { CellContext, ColumnDef } from "@tanstack/react-table";
import { AnalyteHourGrouping } from "../../hooks/useFilteredSampleStream";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useEffect, useState } from "react";
import { getStyleForValue } from "../../handlers/operatingWindow";
import { cn } from "../../lib/utils";
import { SampleFilter } from "../../handlers/samples";
import { round } from "../../lib/round";

dayjs.extend(utc);
dayjs.extend(timezone);

function mean(values?: number[]) {
  if (!values || values.length === 0) {
    return;
  }
  return values.reduce((acc, value) => acc + value, 0) / values.length;
}

const timeSeriesColumns = (current: Dayjs, reverse?: boolean): ColumnDef<AnalyteHourGrouping>[] => {
  const ret = Array.from({ length: 24 }, (_, i) => {
    const date = current.add(1, "day").subtract(i, "hour");
    return {
      id: date.format("YYYY-MM-DDTHH"),
      header: () => <div className={"text-right font-medium p-0"}> {date.format("h A")}</div>,
      accessorFn: (row: AnalyteHourGrouping) => {
        switch (row.formatAs) {
          case "integer":
          return mean(row.values[date.format("YYYY-MM-DDTHH")])?.toFixed(0)
          case "float":
          default:
            return round(mean(row.values[date.format("YYYY-MM-DDTHH")]))
        }
      },
      cell: ({ row }: CellContext<AnalyteHourGrouping, unknown>) => {
        const value = row.getValue(date.format("YYYY-MM-DDTHH")) as number;
        const style = getStyleForValue(value, row.original.operatingWindow);
        return <div className={cn("text-right", "font-medium", "p-0", style)}>{value}</div>;
      },
      enableColumnFilter: false,
      enableSorting: false,
    };
  });
  if (reverse) {
    return ret.reverse();
  }
  return ret;
};

const baseColumns: ColumnDef<AnalyteHourGrouping>[] = [
  {
    id: "sampleLocationAnalyte",
    accessorFn: (row: AnalyteHourGrouping) => `${row.sampleLocation}/${row.analyte}`,
    header: () => (
      <div className={"text-left font-medium"}>
        <span className={"text-foreground"}>Location</span>{" "}
        <span className={"text-muted-foreground/80 text-xxs"}>Analyte</span>
      </div>
    ),
    cell: ({ row }: CellContext<AnalyteHourGrouping, unknown>) => {
      const [sampleLocation, analyte] = (row.getValue("sampleLocationAnalyte") as string).split(
        "/"
      );
      return (
        <div className={"text-left font-medium"}>
          <span className={"text-foreground"}>{sampleLocation}</span>{" "}
          <span className={"text-muted-foreground/80 text-xxs"}>{analyte}</span>
        </div>
      );
    },
    enableColumnFilter: false,
    enableSorting: false,
  },
];

export function useColumns(filter: SampleFilter) {
  dayjs().subtract(1, "day");
  const [reverse, setReverse] = useState(false);

  const [columns, setColumns] = useState<ColumnDef<AnalyteHourGrouping>[]>([]);
  useEffect(() => {
    setColumns([
      ...baseColumns,
      ...timeSeriesColumns(filter.startDate || dayjs().subtract(1, "day"), reverse),
    ]);
  }, [filter, reverse]);
  return {
    columns,
    setReverse,
    reverse,
  };
}
