import { SampleFilter, SampleResponse, streamFilteredSamples } from "../handlers/samples";
import dayjs from "dayjs";
import { getOperatingWindow, OperatingWindow } from "../handlers/operatingWindow";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useQuery } from "@tanstack/react-query";
import { consumePaginatedData, Datum, UseOpcDataOptions } from "../handlers/opcData";

dayjs.extend(utc);
dayjs.extend(timezone);

export enum FormatAs {
  Float = "float",
  Integer = "integer",
}

export type AnalyteHourGrouping = {
  sampleLocation: string;
  analyte: string;
  values: Record<string, number[]>;
  operatingWindow?: OperatingWindow;
  formatAs?: FormatAs;
};

interface UseTimeSeriesSampleStreamOptions {
  filter: SampleFilter;
  rows: [string, string][];
  opcData?:
    | UseOpcDataOptions<Record<string, AnalyteHourGrouping>>
    | UseOpcDataOptions<Record<string, AnalyteHourGrouping>>[];
}

export function useTimeSeriesSampleStream({
  filter,
  opcData,
  rows,
}: UseTimeSeriesSampleStreamOptions) {
  const { data, isLoading, isError } = useQuery({
    refetchInterval: 60000,
    queryKey: ["timeSeriesSampleStream", JSON.stringify(filter), JSON.stringify(opcData)],
    queryFn: async () => {
      console.log("querying opc data", opcData);
      const [samples, opcResult] = await Promise.all([
        consumeSampleStream(filter),
        consumeOpcData(opcData, filter.startDate?.toDate(), filter.endDate?.toDate()),
      ]);
      const idx = {
        ...samples,
        ...opcResult,
      };
      return rows.reduce((acc, [sampleLocation, analyte]) => {
        const key = `${sampleLocation}.${analyte}`;
        const record = idx[key];
        if (!record) {
          return [
            ...acc,
            {
              sampleLocation,
              analyte,
              values: {},
            },
          ];
        }
        return [...acc, record];
      }, [] as AnalyteHourGrouping[]);
    },
  });

  return { data, isLoading, isError };
}

export async function consumeSampleStream(
  filter: SampleFilter
): Promise<Record<string, AnalyteHourGrouping>> {
  const samples = await streamFilteredSamples(filter);
  if (samples instanceof Error) {
    throw samples;
  }
  const ret = samples.reduce((acc, sample) => {
    return updateData(acc, sample);
  }, {} as Record<string, AnalyteHourGrouping>);
  console.log("sample stream", ret);
  return ret;
}

function updateData(
  into: Record<string, AnalyteHourGrouping>,
  sample: SampleResponse
): Record<string, AnalyteHourGrouping> {
  const dateHour = dayjs.utc(sample.collectDT).local().format("YYYY-MM-DDTHH");
  const sampleLookup = sample.sampleLocation + "." + sample.analyte;
  const existingRecord = into[sampleLookup];

  if (!existingRecord) {
    const newRecord: AnalyteHourGrouping = {
      sampleLocation: sample.sampleLocation,
      analyte: sample.analyte,
      values: { [dateHour]: [sample.resultValue] },
      operatingWindow: getOperatingWindow({
        sampleLocation: sample.sampleLocation,
        analyte: sample.analyte,
      }),
    };

    return {
      ...into,
      [sampleLookup]: newRecord,
    };
  }

  const updatedValues = {
    ...existingRecord.values,
    [dateHour]: [...(existingRecord.values[dateHour] || []), sample.resultValue],
  };

  return {
    ...into,
    [sampleLookup]: {
      ...existingRecord,
      values: updatedValues,
    },
  };
}

async function consumeSingleOpcData<T extends Record<string, AnalyteHourGrouping>>(
  opcData: UseOpcDataOptions<T>
): Promise<T> {
  try {
    const data = await consumePaginatedData(opcData);
    console.log("opc data", data);
    if (!opcData.reducer) {
      return dataToGrouping<T>(data, opcData.name);
    }
    console.log("using reducer with data", data);
    const result = opcData.reducer(data);
    console.log("reduced", result);
    return result;
  } catch (e) {
    console.error("error consuming opc data", e);
    return {} as T;
  }
}

async function consumeOpcData<T extends Record<string, AnalyteHourGrouping>>(
  opcData?: UseOpcDataOptions<T> | Array<UseOpcDataOptions<T>>,
  startDate?: Date,
  endDate?: Date
): Promise<T> {
  if (!opcData) {
    return {} as T;
  }
  console.log("opc data", opcData);
  if (!Array.isArray(opcData)) {
    return consumeSingleOpcData({
      ...opcData,
      start: startDate,
      end: endDate,
    });
  }
  const data = await Promise.all(
    opcData.map((datum) => consumeSingleOpcData({ ...datum, start: startDate, end: endDate }))
  )
  return data.reduce((acc, value) => ({
    ...acc,
    ...value,
  }), {} as T)
}

function dataToGrouping<T extends Record<string, AnalyteHourGrouping>>(
  data: Datum[],
  name?: string
): T {
  return data.reduce((acc, datum) => addDatumToGrouping(acc, datum, name), {} as T);
}

function addDatumToGrouping<T extends Record<string, AnalyteHourGrouping>>(
  acc: T,
  { time, value, tag }: Datum,
  name?: string
): T {
  const dateHour = dayjs.utc(time.toISOString()).local().format("YYYY-MM-DDTHH");
  const sampleLocation = name || tag.name;
  const sampleLookup = sampleLocation + ".";
  const existingRecord = acc[sampleLookup];
  if (!existingRecord) {
    const newRecord: AnalyteHourGrouping = {
      sampleLocation,
      analyte: "",
      values: { [dateHour]: [value as number] },
    };
    return {
      ...acc,
      [sampleLookup]: newRecord,
    };
  }
  return {
    ...acc,
    [sampleLookup]: {
      ...existingRecord,
      values: {
        ...existingRecord.values,
        [dateHour]: [...(existingRecord.values[dateHour] || []), value as number],
      },
    },
  };
}
