import DailyOpTargets from "./layouts/dashboards/LabDash/DailyOpTargets";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import Icon from "@mui/material/Icon";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import ListIcon from "@mui/icons-material/List";
import CreateDowntimePage from "./pages/Downtimes/CreateDowntimePage";
import ListDowntimesPage from "./pages/Downtimes/ListDowntimesPage";
import LabEntryDataGridPage from "./pages/LabSheet/LabEntryDataGridPage";
import HouseIcon from "@mui/icons-material/House";
import ScienceIcon from "@mui/icons-material/Science";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import { LossesPage } from "./pages/LabSheets/LossesPage";
import { BeetEndPage } from "./pages/LabSheets/BeetEnd";
import { SugarEndPage } from "./pages/LabSheets/SugarEndPage";
import { ProductionSugarPage } from "./pages/LabSheets/ProductionSugarPage";
import { RealtimeDashboardPage } from "./pages/RealtimeDashboard";
import { LabDataDashboardPage } from "./pages/Dashboard";
import { toFilter, UnitOpPage } from "./pages/Dashboard/unit-op-page";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    route: "/vfactory/signin-oidc",

    icon: <HouseIcon />,
    collapse: [
      {
        name: "Realtime",
        key: "realtime",
        route: "/vfactory/realtime",
        component: <RealtimeDashboardPage />,
        icon: <Icon fontSize="medium">Virtual Factory</Icon>,
      },
    ],
  },
  {
    type: "collapse",
    name: "Lab Data",
    key: "Facility",
    route: "/dashboards/Wsc/Facility",
    icon: <ScienceIcon />,
    component: <UnitOpPage initialUnitOp="None" initialProcessLocation="None" />,
    collapse: [
      {
        name: "Beet End",
        key: "beetend",
        route: "/vfactory/beet-end",
        component: (
          <LabDataDashboardPage
            key={"beetEnd"}
            dataKey={"beetEnd"}
            initialFilter={toFilter({
              initialUnitOp: "None",
              initialProcessLocation: "beetEnd",
            })}
          />
        ),
      },
      {
        name: "Sugar End",
        key: "sugarend",
        route: "/vfactory/sugar-end",
        component: (
          <LabDataDashboardPage
            key={"sugarEnd"}
            dataKey={"sugarEnd"}
            initialFilter={toFilter({
              initialUnitOp: "None",
              initialProcessLocation: "sugarEnd",
            })}
          />
        ),
      },
      {
        name: "Query builder",
        key: "dash",
        route: "/vfactory/dash",
        component: <LabDataDashboardPage />,
      },
      {
        name: "Lab Entry Sheet",
        key: "labEntrySheet",
        route: "/vfactory/labEntrySheet",
        component: <LabEntryDataGridPage />,
        icon: <Icon fontSize="medium">Virtual Factory</Icon>,
      },
      {
        type: "collapse",
        name: "Lab Sheets",
        key: "labSheets",
        route: "/vfactory/labsheets",

        icon: <Icon fontSize="medium">Virtual Factory</Icon>,
        collapse: [
          {
            name: "Beet End",
            key: "beetEndLabSheet",
            route: "/vfactory/labsheets/beetEnd",
            component: <BeetEndPage />,
            icon: <Icon fontSize="medium">Virtual Factory</Icon>,
          },
          {
            name: "Sugar End",
            key: "sugarEndLabSheet",
            route: "/vfactory/labsheets/sugarEnd",
            component: <SugarEndPage />,
            icon: <Icon fontSize="medium">Virtual Factory</Icon>,
          },
          {
            name: "Production Sugar",
            key: "productionSugarLabSheet",
            route: "/vfactory/labsheets/productionSugar",
            component: <ProductionSugarPage />,
            icon: <Icon fontSize="medium">Virtual Factory</Icon>,
          },
          {
            name: "Losses",
            key: "lossesLabSheet",
            route: "/vfactory/labsheets/losses",
            component: <LossesPage />,
            icon: <Icon fontSize="medium">Virtual Factory</Icon>,
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    name: "Operational Targets",
    key: "opTargs",
    route: "/vfactory/operationalTargets",
    icon: <ModeStandbyIcon />,
    collapse: [
      {
        name: "Operational Targets",
        key: "opTargs",
        route: "/vfactory/operationalTargets",
        component: <DailyOpTargets />,
        icon: <Icon fontSize="medium">Virtual Factory</Icon>,
      },
    ],
  },
  {
    type: "collapse",
    name: "Downtime",
    key: "downtime",
    icon: <TimelapseIcon />,
    collapse: [
      {
        name: "Submit Downtime",
        key: "downtimeForm",
        route: "/vfactory/downtimes/new",
        component: CreateDowntimePage(),
        icon: <DynamicFormIcon />,
      },
      {
        name: "Review Downtime Entries",
        key: "downtimeList",
        route: "/vfactory/downtimes",
        component: ListDowntimesPage(),
        icon: <ListIcon />,
      },
    ],
  },
];

export default routes;
