import { Page } from "../../layouts/pages/Page";
import { SampleTimeSeriesTable } from "../../components/Samples/SampleTimeSeriesTable";
import { UseOpcDataOptions } from "../../handlers/opcData";
import { AnalyteHourGrouping } from "../../hooks/useFilteredSampleStream";

interface LabSheetPageProps {
  sampleLocations: string[];
  analytes: string[];
  rows: [string, string][];
  opcData?:
    | UseOpcDataOptions<Record<string, AnalyteHourGrouping>>
    | UseOpcDataOptions<Record<string, AnalyteHourGrouping>>[];
}

export const LabSheetPage = ({ sampleLocations, analytes, rows, opcData }: LabSheetPageProps) => {
  return (
    <Page>
      <SampleTimeSeriesTable
        rows={rows}
        sampleLocations={sampleLocations}
        analytes={analytes}
        opcData={opcData}
      />
    </Page>
  );
};
