import { RefreshCcw } from "lucide-react";
import { SampleFilter } from "../../handlers/samples";
import {
  AnalyteHourGrouping,
  useTimeSeriesSampleStream,
} from "../../hooks/useFilteredSampleStream";
import { DataTable } from "../DataTable/DataTable";
import { useColumns } from "./columns";
import { Button } from "../ui/button";
import { useState } from "react";
import { DatePicker } from "../ui/date-picker";
import dayjs from "dayjs";
import { LoadingSpinner } from "../ui/loading-spinner";
import { UseOpcDataOptions } from "../../handlers/opcData";

type SampleTimeSeriesTableProps = SampleFilter & {
  rows: [string, string][];
  opcData?:
    | UseOpcDataOptions<Record<string, AnalyteHourGrouping>>
    | UseOpcDataOptions<Record<string, AnalyteHourGrouping>>[];
};

export const SampleTimeSeriesTable = ({
  sampleLocations,
  analytes,
  rows,
  opcData,
}: SampleTimeSeriesTableProps) => {
  const [filter, setFilter] = useState<SampleFilter>({
    sampleLocations,
    analytes,
    startDate: dayjs().subtract(1, "day"),
    endDate: dayjs(),
  });
  const { columns, setReverse, reverse } = useColumns(filter);

  const { data, isLoading } = useTimeSeriesSampleStream({ filter, rows, opcData });

  return (
    <div className={"flex w-full h-full flex-col"}>
      <TableMenu reverse={reverse} setReverse={setReverse} filter={filter} setFilter={setFilter} />
      {isLoading && (
        <div className={"flex items-center mb-2 space-x-4 justify-center h-screen"}>
          <LoadingSpinner />
          Loading...
        </div>
      )}
      {data && (
        <DataTable
          columns={columns}
          data={data}
          columnPinning={{
            left: ["sampleLocationAnalyte"],
          }}
        />
      )}
    </div>
  );
};

interface TableMenuProps {
  reverse: boolean;
  setReverse: (reverse: boolean) => void;
  filter: SampleFilter;
  setFilter: (filter: SampleFilter) => void;
}

function TableMenu({ reverse, setReverse, filter, setFilter }: TableMenuProps) {
  const onReverse = () => {
    setReverse(!reverse);
  };

  const onDateChange = (date: Date | undefined) => {
    setFilter({ ...filter, startDate: dayjs(date).subtract(1, "day"), endDate: dayjs(date) });
  };

  return (
    <div className={"flex items-center mb-2 space-x-4 w-full overflow-x-auto p-1"}>
      <Button onClick={onReverse}>
        <RefreshCcw className="mr-2 h-4 w-4" /> {reverse ? "To Descending" : "To Ascending"}
      </Button>
      <div className={"flex flex-col justify-start space-y-2 text-sm font-medium text-gray-500"}>
        24 hour period ending
        <DatePicker
          date={filter.endDate?.toDate()}
          onSelect={onDateChange}
          userPrompt={"Select Start Date"}
        />
      </div>
      <Button
        onClick={() => {
          const date = dayjs();
          setFilter({ ...filter, startDate: date.subtract(1, "day"), endDate: date });
        }}
      >
        Last 24 Hours
      </Button>
      <Button
        onClick={() => {
          // midnight yesterday to midnight today
          const date = dayjs().startOf("day").subtract(1, "day");
          setFilter({
            ...filter,
            startDate: date,
            endDate: date.add(23, "hour").add(59, "minute").add(59, "second"),
          });
        }}
      >
        Yesterday
      </Button>
    </div>
  );
}
